import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async getChannelFees(channelId, year, month, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/report/channel/${channelId}/fee`,
        {
          params: {
            locationId: locationId,
            month: month,
            year: year
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}
