<template>
  <div>
    <p class="title">Cyberdigma Channel fee</p>

    <MonthYearSelector
      @monthYearSelected="monthYearSelected"
      :isLoadingLeft="isLoadingLeft"
      :isLoadingRight="isLoadingRight"
      :showMonthSelector="true"
      :showYearSelector="true"
      :month="month"
      :year="year"
    />

    <Loader v-if="isLoading" />

    <table
      v-if="data"
      class="table is-fullwidth is-striped is-narrow is-hoverable"
    >
      <thead>
        <tr>
          <th>Location</th>
          <th class="has-text-right">Fixed fee</th>
          <th class="has-text-right">%</th>
          <th class="has-text-right">Fee amount</th>
          <th class="has-text-right">Nr of transactions</th>
          <th class="has-text-right">Transactions fee</th>
          <th class="has-text-right">%</th>
          <th class="has-text-right">Fee amount</th>
          <th class="has-text-right">Total fee</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(fee, index) in fees" :key="index">
          <td>{{ fee.LocationName }}</td>
          <td class="has-text-right">
            {{ fee.FixedFee | toCurrency('nl-NL', 'EUR') }}
          </td>
          <td class="has-text-right">{{ fee.FixedFeePercentage }}%</td>
          <td class="has-text-right">
            {{ fee.FixedFeeAmount | toCurrency('nl-NL', 'EUR') }}
          </td>
          <td class="has-text-right">{{ fee.NrOfTransactions }}</td>
          <td class="has-text-right">
            {{ fee.TransactionFee | toCurrency('nl-NL', 'EUR') }}
          </td>
          <td class="has-text-right">{{ fee.TransactionFeePercentage }}%</td>
          <td class="has-text-right">
            {{ fee.TransactionFeeAmount | toCurrency('nl-NL', 'EUR') }}
          </td>
          <td class="has-text-right">
            {{ fee.TotalFee | toCurrency('nl-NL', 'EUR') }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="8" class="has-text-weight-bold">
            Total billable by Cyberdigma
          </td>
          <td class="has-text-right has-text-weight-bold">
            {{ data.TotalFee | toCurrency('nl-NL', 'EUR') }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import reportProvider from '@/providers/report'

export default {
  components: {
    Loader: () => import('@/components/UI/Loader'),
    MonthYearSelector: () => import('@/components/UI/MonthYearSelector'),
  },

  props: {},

  data() {
    return {
      channelId: Number(),
      isLoading: false,
      isLoadingLeft: false,
      isLoadingRight: false,
      locationId: 0,
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      data: null,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),

    fees() {
      let fees = []

      if (this.data) {
        fees = this.data.Locations
      }

      return fees
    },

    grandTotal() {
      let total = 0

      if (this.fees && this.fees.length > 0) {
        for (let fee of this.fees) {
          total += fee.TotalFee
        }
      }

      return total
    },
  },

  created() {
    this.getChannelFees()
  },

  methods: {
    monthYearSelected(data) {
      let self = this

      self.month = data.month
      self.year = data.year

      this.newInvoiceCounter = 0
      this.showProgressBar = false

      this.getChannelFees()
    },

    getChannelFees() {
      this.data = null

      if (!this.isLoading) {
        this.isLoading = true

        reportProvider.methods
          .getChannelFees(
            this.channel.Id,
            this.year,
            this.month,
            this.locationId
          )
          .then((response) => {
            if (response.status === 200) {
              this.data = response.data
            }
          })
          .catch((error) => {})
          .finally(() => {
            this.isLoading = false
            this.isLoadingLeft = false
            this.isLoadingRight = false
          })
      }
    },
  },
}
</script>

<style></style>
